import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/modules';
import { Link, useNavigate } from 'react-router-dom';

import sliderImages from 'constants/jsons/slider-images';

import styles from './Home.module.scss';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { firstFiveDaysSchedule, lastFiveDaysSchedule } from 'constants/jsons/schedule';
import Footer from 'components/generics/footer/Footer';

function renderScheduleItem(schedule, type) {

	let scheduleItemClassName = styles.scheduleItem;

	if (type === 'left') {
		scheduleItemClassName += ` ${styles.left}`;
	}

	return (
		<div className={scheduleItemClassName}>
			<div className={styles.dayLabelContainer}><label className={styles.dayLabel}>{schedule.day}</label></div>
			<label className={styles.infoLabel}>{schedule.label}</label>
		</div>
	);

}

function renderScheduleList(scheduleList, type) {

	return (
		<div className={styles.scheduleList}>
			{
				scheduleList.map((schedule) => (
					renderScheduleItem(schedule, type)
				))
			}
		</div>
	);
}

export function renderTentativeSchedule() {

	return (
		<div className={styles.tentativeScheduleCotainer}>
			<div className={styles.header}>
				<label className={styles.sectionHeading}>Tentative Schedule</label>
			</div>
			<div className={styles.scheduleListContainer}>
				{renderScheduleList(firstFiveDaysSchedule, 'left')}
				{renderScheduleList(lastFiveDaysSchedule, 'right')}
			</div>
		</div>
	);
}

function Home({ loginStatus }) {

	const navigate = useNavigate();

	function renderImageSlider() {

		const sliderContainerAttributes = {
			centeredSlides: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			// navigation: true,
			effect: 'fade',
			loop: true,
			modules: [Autoplay, EffectFade, Pagination, Navigation],
			className: styles.sliderContainer
		};

		return (
			<Swiper {...sliderContainerAttributes}>
				{
					sliderImages.map((image, index) => (
						<SwiperSlide key={index} className={styles.swiperSlide}>
							<img src={image} className={styles.sliderImage} alt={`rps-yatra-${index}`} />
						</SwiperSlide>
					))
				}
			</Swiper>
		);

	}
	function renderContentControls() {

		const signupNowControlAttributes = {
			className: styles.signupNowControl,
			onClick() {
				navigate('/signup');
			}
		};
		const loginNowControlAttributes = {
			className: styles.signupNowControl,
			onClick() {
				navigate('/login');
			}
		};
		const registerNowControlAttributes = {
			className: styles.signupNowControl,
			onClick() {
				navigate('/registration-list');
			}
		};
		const sponsorControlAttributes = {
			className: styles.signupNowControl,
			onClick() {
				navigate('/sponsorship');
			}
		};

		return (
			<div className={styles.contentControlsContainer}>
				{!loginStatus && <button {...signupNowControlAttributes}>Sign up</button>}
				{!loginStatus && <button {...loginNowControlAttributes}>Login</button>}
				{loginStatus && <button {...registerNowControlAttributes}>Register</button>}
				{<button {...sponsorControlAttributes}>Sponsor</button>}
			</div>
		);

	}

	function renderInformationContent() {

		return (
			<div className={styles.informationContentContainer}>
				<div className={styles.informationContent}>
					<label className={styles.yatraText}>Yatra 2025</label>
					<label className={styles.sectionHeading}>Seek Blessings in Sri Lanka, Guided by: <br /> HH Romapada Swami & other guest speakers</label>
					<label className={styles.contentLabel}>Join us for a spiritually enriching pilgrimage to Sri Lanka in 2025, where we will visit the sacred sites where the timeless pastimes of the Ramayana unfolded. This yatra will be guided by HH Romapada Swami and other guest speakers who will illuminate the sacred stories of Lord Rama, Sita, Lakshmana and Hanuman with profound wisdom and devotion.</label>
					<label className={styles.contentLabel}>If you are looking to surcharge your life with purpose and sensitivity and want to learn the art of getting pearls from the ocean of scriptures, you will find this yatra helpful. In addition to lectures and visiting places, we will be having special events like Musical Katha.</label>
					{renderContentControls()}
				</div>
			</div>
		);

	}
	

	return (
		<div className={styles.homeMain}>
			{renderImageSlider()}
			{renderInformationContent()}
			<div className={styles.horizontalDivider}></div>
			{renderTentativeSchedule()}
			<Footer />
		</div>
	);
}

export default Home;
